"use client"
import { getIcon } from '@/lib/get-icon'
import Button from '@/app/ui/button'
import Container from '@/ui/container'
import React from 'react'
import homeIcons from '../icons/home-icons'
import Link from 'next/link'
import { ROUTES } from '@/utils/routes'

export default function PlanningBanner({title}:any) {
   
    // const parallax = useParallax<HTMLDivElement>({
    //     translateX: [150, -180, 'easeInOut'],
    //     opacity: [0, 1, 'easeOutBack'],
        
    //   });

    //   const parallaxTwo =  useParallax<HTMLDivElement>({
    //     translateY: [200,0, 'easeInOut'],
    //     opacity: [0, 1, 'easeOutBack'],
    //   });

  return (
    <Container className="relative max-w-full flex flex-col gap-6 justify-center items-center bg-[#611F69] h-[500px] overflow-hidden before:bg-[url('/images/net-bg-white.svg')] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:pointer-events-none">
    <div className="text-[56px] xs:text-2xl sm:text-4xl sm:px-20 font-semibold xs:text-center sm:text-center text-white xs:px-5">{title ? title : "Are you planning to go Omnichannel?"}</div>
    <span >
    <Link href={ROUTES?.CONTACTUS}><Button  variant="secondary">Let us Talk {getIcon({ iconList: homeIcons, iconName: 'ButtonArrowRight', stroke: 'black' })}</Button></Link>
    </span>
  </Container>
  )
}
